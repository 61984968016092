// You can add global styles to this file, and also import other style files 

.p-tooltip-text {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

body .p-overlaypanel {
  margin-top: initial;
  border-radius: 0px 0px 6px 6px;
}

// Add extra space to the main layout when displaying the application navigation at the bottom on small screens
@media screen and (max-width: 992px) {
  body .layout-wrapper .layout-container .layout-main {
    padding-bottom: 64px;
  }
}