/* You can add global styles to this file, and also import other style files */
// @import '~@arc/arc-shared-frontend/assets/@arc/theme/theme-bt';
@import '../node_modules/@arc/arc-shared-frontend/src/assets/@arc/theme/theme-bt.scss';
@import '../node_modules/@arc/account-frontend/assets/@arc/sass/arc-overrides/index';
@import '../node_modules/@arc/arc-dispute-frontend/assets/@arc/sass/arc-overrides/index';
@import '../node_modules/@arc/arc-configuration-frontend/assets/@arc/sass/arc-overrides/index';
@import '../node_modules/@arc/arc-user-frontend/assets/@arc/sass/arc-overrides/index';
// @import '../node_modules/@arc/arc-cashapp-frontend/assets/@arc/sass/arc-overrides/index';
@import '../node_modules/@arc/arc-creditapp-frontend/assets/@arc/sass/arc-overrides/global';
@import '../node_modules/@arc/arc-credit-common/assets/@arc/sass/arc-overrides/global';
//@import '~@arc/card-merchant-registration/assets/@arc/arc-overrides/index';

/* You can add global styles to this file, and also import other style files */

p-dropdown {
  width: auto;
}
/* in-flight clone */
.gu-mirror {
  cursor: ns-resize !important;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;

  .drag-handle {
    cursor: ns-resize !important;
  }
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}
